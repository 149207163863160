.body {
  background: #f5d6e9;
  height: 100vh;
  display: flex;
  justify-items: center;
  align-items: center;
  overflow: hidden;

  
}
.main{
  width: fit-content;
  margin: 0 auto;
display: flex;
justify-items: center;
align-items: center;
flex-direction: column;
}
.btn {
  width: 200px;
height: 50px;
background-color: #890155;
border-radius: 10px;
color: white;
}
.present {
  width: 610px;
  margin: 80px auto;
}
.box, .lid {
  background:
    -webkit-radial-gradient(white 15%, transparent 15.1%),
    -webkit-radial-gradient(white 15%, transparent 15.1%),
  #890155;
  background-position: 0 0, 25px 25px;
  background-size: 50px 50px;
  position: relative;
  margin: 0 auto;
}
.box {
  width: 600px;
  height: 250px;
}
.lid {
  width: 600px;
  height: 70px;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 0 2px;
  background-color: #890155;
  /* transitions */
  top: 0;
  left: 0;
  transition: 
    top ease-out 0.5s,
    left ease-out 0.5s,
    transform ease-out 0.5s;
}
.box span, .lid span {
  position: absolute;
  display: block;
  background: #322C58;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.1);
}
.box span:first-child {
  width: 100%;
  height: 60px;
  top: 80px;
}
.box span:last-child, .lid span {
  width: 60px;
  height: 100%;
  left: 170px;
}
.lid span {
  left: 172px;
}
.promo {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
  color: #333;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  position: relative;
   height: 0;
  top: 10px;
  transition: all ease-out 0.7s;
}
.promo p {
  font-size: 24px;
  margin: 0;
  font-weight: 400;
  user-select: none;
}
.promo h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  user-select: none;
}
.andesha-img{
  object-fit: cover;
  object-position: top;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  margin-bottom: 20px;
  -webkit-user-drag: none;
  user-select: none;
}
.heart {
    position: fixed;
    top: -1vh;
    font-size: 2rem;
    transform: translateY(0);
    animation: fall 3s linear forwards;
    user-select: none;
}

@keyframes fall {
    to {
        transform: translateY(105vh);
    }
}
@media screen and (max-width: 420px){
  .present {
  width: 350px;
}
.box {
  width: 300px;
  height: 125px;
}
.lid {
  width: 300px;
  height: 35px;
}
.box span:first-child {
  width: 100%;
  height: 30px;
  top: 50px;
}
.box span:last-child, .lid span {
  width: 30px;
  height: 100%;
  left: 48px;
}
.lid span {
  left: 48px;
}
.promo {
  font-size: 30px;
}
.promo p {
  font-size: 20px;
}
.promo h2 {
  font-size: 35px;
}
.andesha-img{
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
}
.btn {
  width: 140px;
height: 35px;

}
}